// db.js
import Dexie from 'dexie';

export const db = new Dexie('database');
db.version(1).stores({
    httpRequests: "++id,url,parameters,response"
});

db.version(2).stores({
    pagesWithExplanationDisabled: "path",
    dismissedExplanations: "path,explanationId"
})

db.version(3).stores({
    pagesWithExplanationDisabled: null,
    dismissedExplanations: null
})

db.version(4).stores({
    pagesWithExplanationDisabled: "pageId",
    dismissedExplanations: "pageId,explanationId"
})

db.version(5).stores({
    dismissedExplanations: null
})

db.version(6).stores({
    dismissedExplanations: "++id,pageId,explanationId"
})

db.version(7).stores({
    httpRequests: "++id,url,parameters,response,deliable"
}).upgrade(trans => {
    return trans.table("httpRequests").toCollection().modify(request => {
        request.deliable = false
    });
});

db.version(8).stores({
    httpRequests: "++id,url,parameters,response,deliable"
}).upgrade(trans => {
    return trans.table("httpRequests").toCollection().modify(request => {
        request.deliable = 0
    });
});

db.version(9).stores({
    httpRequests: "++id,url,parameters,response"
}).upgrade(trans => {
    return trans.table("httpRequests").toCollection().modify(request => {
        delete request.deliable
    });
});


db.version(10).stores({
    httpRequests: "++id,url,parameters,response,deliable"
}).upgrade(trans => {
    return trans.table("httpRequests").toCollection().modify(request => {
        request.deliable = 0
    });
});